require=(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({9:[function(require,module,exports){
'use strict';

require('./_components/jquery-global.js');

var _owl = require('owl.carousel');

// https://owlcarousel2.github.io/OwlCarousel2/

// read product record based on given ID
$(document).ready(function () {
	init();
});

function init() {

	var productSlider = $('#pageHeroSlider').owlCarousel({
		loop: true,
		margin: 0,
		// responsiveClass:true,
		nav: false,
		singleItem: true,
		autoplay: true,
		autoplayTimeout: 3000,
		autoplayHoverPause: false,
		autoWidth: false,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		responsive: {
			0: {
				items: 1,
				nav: false
			},
			600: {
				items: 1,
				nav: false
			},
			800: {
				items: 1,
				nav: false
			},
			1000: {
				items: 1,
				nav: false,
				loop: true
			}
		}
	});
}

},{"./_components/jquery-global.js":5,"owl.carousel":2}]},{},[9]);
